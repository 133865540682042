body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span {
  font-family: 'Mulish', sans-serif;
  font-weight: 500; 
}

h1, p {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
}

b {
  font-family: 'Mulish', sans-serif;
  font-weight: 1000; 
}