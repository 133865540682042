.App {
  background: linear-gradient(167deg, rgba(18,18,18,1) 35%, rgba(57,83,255,1) 100%);
  backdrop-filter: blur(100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 30px 100px 0 100px; 
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 1300px) {
    padding: 20px 24px 0 24px;
  }
}

.App-logo {
  height: 69px;
  width: 177px;
  pointer-events: none;

  @media (max-width: 500px) {
    height: 48px;
  width: 154px;
  }
}

.limitation {
  width: 18px;
  height: 18px;
}








