.timer_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  p {
    margin: 0;
    padding: 15px 36px;
    border-radius: 24px;
    background: #FFFFFF33;
    font-family: Mulish;
    font-size: 32px;
    font-weight: 700;
    line-height: 40.16px;
    box-shadow: 4px 4px 4px 0px #0000001A inset;
    box-shadow: -4px -4px 4px 0px #0000001A inset;
    box-shadow: 4px -4px 4px 0px #0000001A inset;
    box-shadow: -4px 4px 4px 0px #0000001A inset;

    @media (max-width: 1300px) {
      padding: 15px 0px;
      font-size: 24px;
      line-height: 30.12px;
      width: 104px;
      justify-content: center;

    }

    @media (max-width: 500px) {
      width: 72px;
      font-size: 20px;
      font-weight: 700;
      line-height: 25.1px;
    }
  }

}

.vertical-dots {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: 500px) {
   gap: 2px;
  }
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #FFFFFF;

  @media (max-width: 500px) {
    width: 5px;
    height: 5px;
   }
}