.mobile_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
  height: 100%;
}

.App-link_mobile {
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: Mulish;
  font-size: 24px;
  font-weight: 1000;
  line-height: 30.12px;
  padding: 20px 0;
  border-image-source: linear-gradient(116.97deg, rgba(255, 255, 255, 0) 16.71%, rgba(255, 255, 255, 0.4) 98.19%);
  background: #0075FF;
  border-radius: 24px;
  margin-bottom: 60px;
  margin-top: 22px;
  width: 360px;
  animation: pulse 2s infinite ease-in-out; 


  @media (max-width: 500px) {
    width: 328px;  
    font-size: 16.93px;
    font-weight: 1000;
    line-height: 21.25px;
  }
}

@keyframes pulse {
  0%, 100% {
    box-shadow: 0px 0px 10px 0px #0075FF;
  }
  50% {
    box-shadow: 0px 0px 30px 10px #0075FF;
  }
}

.content_block_mobile {
  display: flex;
  flex-direction: column;
  width: 360px;
  gap: 20px;
  text-align: center;

  @media (max-width: 500px) {
    gap: 10px;
    width: 264px;
   }
}

.image_block_mobile {
  position: relative;
  height: 389px;

  @media (max-width: 500px) {
    height: 190px;
   }
}

.smokeBg_mobile {
  position: absolute;
  width: 606px;
  height: 405px;
  top: -10px;
  left: 50%;
  transform: translate(-50%);

  @media (max-width: 500px) {
    /* left: -180px; */
    width: 302px;
    height: 202px;
   }
}

.fly_mobile {
  position: absolute;
  width: 389px;
  height: 389px;
  left: 50%;
  transform: translate(-50%);
  top: -20px;

  @media (max-width: 500px) {
    width: 200px;
    height: 200px;
   }
}

.title_mobile {
  font-size: 28px;
  font-weight: 500;
  line-height: 35.14px;
  margin: 0;

  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 25.1px;
   }
}

.percent_mobile {
  font-size: 28px;
  font-weight: 900;
  line-height: 35.14px;  
  margin: 0;

  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 25.1px;
   }
}

.block_cash {
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    gap: 12px;
   }
}

.text_mobile, .text_desc_mobile  {
  font-size: 64px;
  font-weight: 900;
  line-height: 64px;
  white-space: nowrap;
  background: linear-gradient(90deg, #E0AA3E 0%, #F9F295 33.4%, #E0AA3E 67%, #F7EF8A 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  @media (max-width: 500px) {
    font-size: 48px;
    line-height: 48px;
   }
}

.text_desc_mobile {
  font-size: 48px;
  line-height: 60.24px;

  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 30px;
   }
}

.footer_mobile {
  display: flex;
  flex-direction: column;
  bottom: 20px;

  max-width: 1100px;
  width: 100%;
  gap: 8px;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    white-space: wrap;

    @media (max-width: 680px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.block_mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
}